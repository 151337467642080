import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Post Hackathon Meetup`}</h1>
    <p>{`Some time has passed since we co-organized the FinTech Hackathon together with the guys from F10 - you can read more about this event in `}<a parentName="p" {...{
        "href": "./f10-hackathon"
      }}>{`this blog post`}</a>{`.`}</p>
    <p>{`To keep the FinTech flag high and give the best startups from the hackathon the chance to present their ideas in front of the potential investors, we went on and provided our `}<a href="https://goo.gl/maps/P7PiuQt39a1A95peA" target="_blank">{`beautiful space`}</a>{` for the `}<inlineCode parentName="p">{`F10 FinTech Meetup`}</inlineCode>{` that took place on October, 7th.`}</p>
    <p>{`Lisa from F10 did a great job presenting F10's future expansion plans for Singapore.`}</p>
    <p>{`Our man in Singapore, Daniel, gave the keynote about all the things Mbanq.`}</p>
    <p style={{
      "textAlign": "center"
    }}>
  <img src="/blog/F10-FinTech-Meetup/daniel.jpg" alt="Daniel about Mbanq" width="750px" />
    </p>
    <p>{`The event was a big success and we would like to thank all the startups that joined us and presented their product ideas.`}</p>
    <p>{`We will organize more event like this and  we are looking forward to connecting with more startups active in the ASEAN region.`}</p>
    <p>{`If you're looking for partners to help you building your startup, accelerate your existing business or expand in Southeast Asia - don't look any further and `}<a parentName="p" {...{
        "href": "../application"
      }}>{`join Mbanq Labs`}</a>{`.`}</p>
    <p style={{
      "textAlign": "center"
    }}>
  <img src="/blog/F10-FinTech-Meetup/mbanq-labs-f10.jpg" alt="Mbanq Labs and F10" width="750px" />
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      